<template>
  <el-container>
      <el-main style="margin-top: 20px">
        <div :class="cuswidth" style="border-left: 1px solid #ccc;border-right: 1px solid #ccc;height: 100%;margin:0 auto;width:380px">
            <div style="border-bottom: 1px solid #ccc;height: 300px">
              <ul id="content">
                <li style="width: 50%">
<!--                  <img src="https://img1.baidu.com/it/u=4001248402,3847064008&fm=253&fmt=auto&app=120&f=JPEG?w=423&h=220" style="padding: 10px;height: 280px;width: 100%"/>-->
                  <img :src="data.bgUrl" style="padding: 10px;height: 120px;width: 100%"/>
                </li>
                <li style="width: 50%">
                  <div style="display: inline-block;height: 70px;width: 100%">
                    <p class="ptag" style="margin-left: 10px;margin-top: 10px">{{data.name}}</p>
                    <div style="font-size: 12px;margin-left: 10px">
                      <p style="margin-left: 10px;"><i class="el-icon-time"></i>{{data.updateTime}}</p>
                      <p style="margin-left: 10px;margin-top: 5px"><i class="dot"></i>{{data.gameType}}<i class="el-icon-coin" style="margin-left: 5px"></i>{{data.diskSpace}}<i class="el-icon-chat-dot-square" style="margin-left: 5px"></i>{{data.gameLang}}</p>
                    </div>
                  </div>
                </li>
              </ul>
              <div style="width: 200px">
                <p class="ptag"  style="font-size: 14px;margin-left: 10px;font-weight: bolder;">
                  <span style="color: #FF9800;"><i class="el-icon-circle-check"></i>加入VIP无限制畅快下载</span>
                  <span v-if="loginname!=''&&limitTime!=''&&unzipcode!=''" style="float: right;color:black;font-size:14px ">激活码：{{unzipcode}}</span>
                </p>
                <div style="width: 200px;height: 110px">
                  <ul v-if="loginname!=''&&limitTime!=''" id="downloadUl">
                    <li v-if="newBaiduUrl!==''">
                      百度云盘：<el-button type="success" @click="copyUrl(newBaiduUrl)">点击复制链接</el-button>
                    </li>
                    <li v-if="tianyiUrl!==''">
                      天翼云盘<span v-if="tianyiPanPwd!==''">(访问码：{{tianyiPanPwd}})</span>：<el-button type="success" @click="copyUrl(tianyiUrl)">点击复制链接</el-button>
                    </li>
                    <li v-if="aliyunUrl!==''">
                      阿里云盘：<el-button type="success" @click="copyUrl(aliyunUrl)">点击复制链接</el-button>
                    </li>
                    <li v-if="xunleiUrl!==''">
                      迅雷云盘：<el-button type="success" @click="copyUrl(xunleiUrl)">点击复制链接</el-button>
                    </li>
                    <li v-if="kuakeUrl!==''">
                      夸克云盘：<el-button type="success" @click="copyUrl(kuakeUrl)">点击复制链接</el-button>
                    </li>
                  </ul>
                  <div v-if="loginname==''" style="border:1px dashed #ccc;width: 350px;height: 100%;color: gray;text-align: center;margin-left: 10px">
                    <span style="line-height: 110px">登录后可查看下载链接</span>
                  </div>
                  <div v-if="loginname!=''&&limitTime==''" style="border:1px dashed #ccc;width: 100%;height: 150px;color: gray;text-align: center;margin-left: 50px">
                    <span style="line-height: 150px">加入<span style="color: #FF9800">VIP</span>可查看下载链接</span>
                  </div>
                  <div v-if="loginname!=''&&limitTime!=''&&newBaiduUrl==''" style="border:1px dashed #ccc;width: 100%;height: 150px;color: gray;text-align: center;margin-left: 50px">
                    <span style="line-height: 150px">马上上架 敬请期待 ≥▽≤ ！！</span>
                  </div>
                </div>
              </div>
            </div>
            <div style="padding: 10px">
              <h3 style="float: left;width: 100%;margin-bottom: 20px">游戏简介：</h3>
              <p class="ptag"  v-html="data.descRemark"></p>
            </div>
        </div>
        <el-dialog title="游戏反馈" :visible.sync="dialogFormVisible" width="500px">
          <el-form>
            <el-form-item label="游戏名称" label-width="120px">
              <el-input v-model="needGame" style="width: 300px" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="备注（选填：问题描述）" label-width="120px">
              <el-input type="textarea" v-model="content" style="width: 300px" autocomplete="off"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="saveNeedGame">确 定</el-button>
          </div>
        </el-dialog>
      </el-main>
  </el-container>
</template>
<script>

export default {
  data() {
    return {
      id: 10,
      data:{},
      gameName:'',
      needGame:"",
      loginname:"",
      dialogFormVisible:false,
      newBaiduUrl:"",
      tianyiUrl:"",
      aliyunUrl:"",
      xunleiUrl:"",
      kuakeUrl:"",
      userId:"",
      limitTime:"",
      unzipcode:"",
      tianyiPanPwd:"",
      cuswidth:"width:380px"
    };
  },
  // 卸载
  beforeDestroy() {
  },
  beforeCreate(){
  },
  created() {
    if (sessionStorage.getItem("userName")) {
      this.loginname = sessionStorage.getItem("userName");
    } else {
      this.loginname = "";
    }

    if (sessionStorage.getItem("userId")) {
      this.userId = sessionStorage.getItem("userId");
    } else {
      this.userId = "";
    }
    this.getVipInfos()
  },
  mounted() {
    let id = this.$route.query.gameId;
    this.id = id
    this.loadDetail()
    this.getDownloadInfos();
  },
  methods: {
    goHome(){
      window.location.href='/'
    },
    getVipInfos(){
      this.$axios.get(this.$gameApiurls.getUserVipInfo)
        .then((res) => {
          if(res.code==0){
            if(res.data!=null){
              this.limitTime = res.data.limitTime;
            }else{
              this.limitTime = "";
            }
          }
        });
    },
    getDownloadInfos(){
      if(this.userId!=""){
        this.$axios.get(this.$gameApiurls.getGameDownLoadInfo+"?id="+this.id)
          .then((res) => {
            let data = res.data
            if(data!=null){
              this.newBaiduUrl = data.newBaiduUrl==null?"":data.newBaiduUrl;
              this.tianyiUrl = data.tianyiUrl==null?"":data.tianyiUrl;
              this.aliyunUrl = data.aliyunUrl==null?"":data.aliyunUrl;
              this.xunleiUrl = data.xunleiUrl==null?"":data.xunleiUrl;
              this.kuakeUrl = data.kuakeUrl==null?"":data.kuakeUrl;
              this.unzipcode = data.fileUnzipCode==null?"":data.fileUnzipCode;
              this.tianyiPanPwd = data.tianyiPanPwd==null?"":data.tianyiPanPwd;
            }
          });
      }
    },
    loadDetail(){
      this.$axios.get(this.$gameApiurls.gameInfo+"?id="+this.id)
        .then((res) => {
          let data = res.data
          this.data = data
        });
    },copyUrl(url){
      // navigator clipboard 需要https等安全上下文
      if (navigator.clipboard && window.isSecureContext) {
        // navigator clipboard 向剪贴板写文本
        this.$message({
          message: '链接已复制到剪贴板',
          type: 'success'
        });
        return navigator.clipboard.writeText(url);
      } else {
        // document.execCommand('copy') 向剪贴板写文本
        let input = document.createElement('input')
        input.style.position = 'fixed'
        input.style.top = '-10000px'
        input.style.zIndex = '-999'
        document.body.appendChild(input)
        input.value = url
        input.focus()
        input.select()
        try {
          let result = document.execCommand('copy')
          document.body.removeChild(input)
          if (!result || result === 'unsuccessful') {
            this.$message({
              message: '复制失败',
              type: 'success'
            });
          } else {
            this.$message({
              message: '链接已复制到剪贴板',
              type: 'success'
            });
          }
        } catch (e) {
          document.body.removeChild(input)
          this.$message({
            message: '当前浏览器不支持复制功能，请检查更新或更换其他浏览器操作',
            type: 'success'
          });
        }
      }
    }
  },
};
</script>

<style scope>
body {
  height: 100vh;
  background-color: #f4fafe;
}
.ptag{
  text-align: left;
  padding: 10px;
}
el-button{
  height: 50px;
}
#content li{
  list-style: none;
  float: left;
}

.dot {
  border-radius: 50%;
  height: 6px;
  margin-right: 5px;
  width: 6px;
  background-color: #fd721f;
  display: inline-block;
}
.el-icon-circle-check{
  color: #FF9800;
  font-weight: bolder;
}
#downloadUl li{
  list-style: none;
  line-height: 35px;
  padding: 10px
}

.input-class .el-input__inner {
  text-align: center;
  border-radius: 30px;
  width: 380px;
  float: right;
  margin-right: 30%;
  margin-top:20px;
}

#headerUl>li{
  float: left;
  list-style: none;
}
  li{
    list-style: none;
  }
  h2{
    font-size: 16px;
  }
.el-icon-coin{
  color: blue;
  font-weight: bolder;
}
.el-icon-chat-dot-square{
  color: green;
  font-weight: bolder;
}
p img{
  width:320px
}
</style>
